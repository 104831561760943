<template>
	<div class="login-container">
		<LoginHeader/>
		<div class="page-content-wrap">
			<div class="cover">
				<img src="../../assets/images/common/pt.jpg" alt="" style="width:730px; height: 550px; border-radius: 5px;">
			</div>
			<div class="login-form">
				<div class="title">欢迎登录!</div>
				<div class="tab">
					<div @click="loginType = 'password'" :class="[loginType === 'password' ? 'active' : '', 'tab-item']">
						账号登录
					</div>
					<div @click="loginType = 'code'" :class="[loginType === 'code' ? 'active' : '', 'tab-item']">短信登录</div>
				</div>
				<div v-if="loginType === 'password'" class="form-content">
					<div class="form-item">
						<input v-model="mobile" type="text" placeholder="请输入手机号">
					</div>
					<div class="form-item">
						<input v-model="password" type="password" placeholder="请输入密码">
					</div>
				</div>
				<div v-if="loginType === 'code'" class="form-content">
					<div class="form-item">
						<input v-model="mobile" type="text" placeholder="请输入手机号">
					</div>
					<div class="form-item">
						<input v-model="code" type="text" placeholder="请输入短信验证码">
						<div @click="getCodeHandle" class="get-code-btn" style="color: #d9262c;">{{ codeText }}</div>
					</div>
				</div>
				<div class="actions">
					<router-link class="forget-password" to="/resetpassword">忘记密码？</router-link>
					<router-link class="regist" to="/regist">立即注册</router-link>
				</div>
				<el-button class="login-button" @click="loginHandle" type="primary">立即登录</el-button>
				<!-- <div @click="loginHandle" class="login-button">立即登录</div> -->
				<div class="other-login">
					<div @click="otherLoginHandle('wechat')" class="item">
						<span class="icon iconfont icon-wechat"></span>
						<div class="text">微信登录</div>
					</div>
					<div @click="otherLoginHandle('qq')" class="item">
						<span class="icon iconfont icon-QQ-circle-fill"></span>
						<div class="text">QQ登录</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import LoginHeader from '@/components/header/header-login.vue'
import md5 from 'js-md5'

export default {
	components: {
		LoginHeader
	},
	data() {
		return {
			baseUrl: process.env.VUE_APP_BASEURL,
			loginType: 'password',
			mobile: '',
			password: '',
			code: '',
			key: process.env.VUE_APP_SIGNKEY,
			codeText: '获取验证码',
			timer: null,
			num: 60
		}
	},
	beforeDestroy() {
		clearInterval(this.timer)
	},
	methods: {
		// 获取验证码
		async getCodeHandle() {
			if (this.num != 60) {
				return false
			}
			if (!this.checktel(this.mobile)) {
				return false
			}
			let timestamp = Date.parse(new Date()).toString();//获取到毫秒的时间戳，精确到毫秒
			timestamp = timestamp.substr(0, 10);//精确到秒
			// const timestamp = parseInt(new Date().getTime() / 1000).toString()
			const params = {
				mobile: this.mobile,
				sign: md5(md5(timestamp) + md5(this.mobile) + md5(this.key)),
				timestamp: Number(timestamp)
			}
			const _this = this
			this.timer = setInterval(() => {
				_this.num = _this.num - 1
				if (_this.num <= 0) {
					clearInterval(_this.timer)
					_this.codeText = '获取验证码'
					_this.num = 60
				} else {
					_this.codeText = _this.num + 's后重试'

				}
			}, 1000)
			await this.$http.post('/common/sendsms', params)
		},
		// 跳转第三方登录
		otherLoginHandle(thirdparty) {
			const url = `${this.baseUrl}/api/thirdparty-login?thirdparty=${thirdparty}&route_path=login`
			window.location.href = url
		},
		// 手机号校验
		checktel(val) {
			if (val == '') {
				this.$message.error('手机号不能为空')
				return false
			}
			let myreg = /^[1][2,3,4,5,7,6,8,9][0-9]{9}$/
			if (!myreg.test(val)) {
				this.$message.error('手机号格式不正确')
				return false
			}
			return true
		},
		// 登录
		async loginHandle() {
			if (this.loginType === 'password') { // 账号密码登录
				if (!this.checktel(this.mobile)) {
					return false
				}
				if (!this.password) {
					this.$message({
						type: 'error',
						message: '密码不能为空',
						duration: 1500
					})
					return false
				}
				const res = await this.$http.post('/api/login', {
					login_type: this.loginType,
					mobile: this.mobile,
					password: this.password
				})
				localStorage.setItem('token', res.token)
				await this.$store.dispatch('setUserInfo')
				this.$router.push({path: '/'})
			} else if (this.loginType === 'code') {
				if (!this.checktel(this.mobile)) {
					return false
				}
				if (!this.code) {
					this.$message({
						type: 'error',
						message: '验证码不能为空',
						duration: 1500
					})
					return false
				}
				const res = await this.$http.post('/api/login', {
					login_type: this.loginType,
					mobile: this.mobile,
					code: this.code
				})
				localStorage.setItem('token', res.token)
				await this.$store.dispatch('setUserInfo')
				this.$router.push({path: '/'})
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.page-content-wrap {
	display: flex;
	justify-content: space-between;
	padding: 30px 0;

	.cover {
	}

	.login-form {
		margin-left: 20px;
		border-radius: 10px;
		flex: 1;
		box-shadow: 0px 0px 8px 0px #ccc;
		padding: 40px 30px;

		.title {
			text-align: center;
			color: #D9262C;
			font-size: 24px;
		}

		.tab {
			display: flex;
			justify-content: space-around;
			font-size: 18px;
			line-height: 1.1;
			color: #999999;
			margin-top: 40px;

			.tab-item {
				cursor: pointer;

				&.active {
					color: #D9262C;
				}
			}
		}

		.form-content {
			margin-top: 25px;

			.form-item {
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-bottom: 1px solid #E6E6E6;

				input {
					outline: none;
					border: 0 none;
					flex: 1;
					line-height: 60px;
					font-size: 16px;
				}

				.get-code-btn {
					color: #3385FF;
					font-size: 16px;
					cursor: pointer;
				}
			}
		}

		.actions {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 20px;

			a {
				text-decoration: none;
				color: #999999;
				font-size: 16px;

				&.regist {
					color: #D9262C;
				}
			}
		}

		.login-button {
			width: 100%;
			height: 45px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 40px;
			font-size: 16px;
		}

		.other-login {
			display: flex;
			justify-content: space-around;
			align-items: center;
			margin-top: 40px;

			.item {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				cursor: pointer;

				.iconfont {
					font-size: 36px;
					color: #43D075;

					&.icon-QQ-circle-fill {
						font-size: 40px;
						color: #3385FF;
					}
				}

				.text {
					margin-top: 15px;
					font-size: 13px;
					color: #999999;
				}
			}
		}
	}
}
</style>
